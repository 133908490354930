import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import imagenMision from '../../Assets/Images/Var/mision.png';
import imagenVision from '../../Assets/Images/Var/vision.png';

function MissionAndVision() {
	let fondoHeader = { backgroundColor: 'transparent' };

	return (
		<Container className="mt-5" id='MisionVision'>
			<Card className="mt-1">
				<Card.Body>
					<Card.Header className="h2" style={fondoHeader}>
						Nuestra Misión, Nuestra Visión
					</Card.Header>

					<Row>
						<Col
							sm={12}
							md={12}
							lg={6}
							className="d-flex flex-column justify-content-center align-items-center"
						>
							<Card style={{ width: '18rem' }} className='border-0'>
								<Card.Img variant="top" src={imagenVision} />
								<Card.Body>
									<Card.Title style={fondoHeader}>Nuestra Visión</Card.Title>
									<Card.Text>
										Servir mejor a nuestros clientes para ser siempre relevantes en sus vidas y
										formar relaciones duraderas.
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>

						<Col
							sm={12}
							md={12}
							lg={6}
							className="d-flex flex-column justify-content-center align-items-center"
						>
							<Card style={{ width: '18rem' }} className='border-0'>
								<Card.Img variant="top" src={imagenMision} />
								<Card.Body>
									<Card.Title style={fondoHeader}>Nuestra Misión</Card.Title>
									<Card.Text>
										Ofrecer una amplia gama de productos, de calidad, bien diseñados a precios
										competitivos.
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
}

export default MissionAndVision;
