import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

// Bloques de la página
import WhoWeAre from './WhoWeAre';
import OurProducts from './OurProducts';
import MissionAndVision from './MissionAndVision';

//Logo WhatsApp
import logoWA from '../../Assets/Images/Logos/whatsapp.png'

function Home() {

	let css = {
		position: 'fixed',
		top: '85%',
		right: '10px'
	}

	return (
		<Container className="mt-5 pt-5 mb-5 pb-5">
			<WhoWeAre />

			<OurProducts />

			<MissionAndVision />

			<a href="https://wa.link/ttghp1" target="_blank" rel="noopener noreferrer" style={css} ><Image src={logoWA} /></a>

		</Container>
	);
}

export default Home;
