import Container from 'react-bootstrap/Container';

//Componentes
import Menu from './Components/Layout/Menu';
import Footer from './Components/Layout/Footer';

//Pages
import Home from './Components/Pages/Home';

function App() {

	return (
		<Container className="text-center">
			<header>
				<Menu />
			</header>

			<main>
				<Home />
			</main>

			<Footer />
		</Container>
	);
}

export default App;

