import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';

//Logo
import logo from '../../Assets/Images/Logos/logo.png';

function Menu() {
	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
			<Container>

				<a href="/">
					<Image src={logo} alt="Logo" width="50" height="50"/>
				</a>

				<Navbar.Brand href="/" className='m-2'>Baumböden</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>

					<Nav>

						<Nav.Item>
							<Nav.Link href="#WhoWeAre">¿Quiénes Somos?</Nav.Link>
						</Nav.Item>

						<Nav.Item>
							<Nav.Link href="#OurProducts">Nuestros Productos</Nav.Link>
						</Nav.Item>

						<Nav.Item>
							<Nav.Link href="#MisionVision">Mision y Vision</Nav.Link>
						</Nav.Item>

					</Nav>

				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Menu;
