import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import imagen from '../../Assets/Images/Floors/im6.jpeg';

function WhoWeAre() {
	let fondoHeader = { backgroundColor: 'transparent' };

	return (
		<Container className="mt-5" id='WhoWeAre'>
			<Card className="mt-1">
				<Card.Body>
					<Row>
						<Col sm={12} md={12} lg={4} className="d-flex flex-column justify-content-center align-items-center">
							<Card.Header className="h2" style={fondoHeader}>
								¿Quiénes Somos?
							</Card.Header>

							<Card.Text className="mt-3 pt-3">
								Somos una empresa joven, pero con una gran experiencia en el rubro de pisos y
								revestimientos, nuestra impronta es la pasión en el desarrollo de proyectos
								residenciales y comerciales. Curiosos e inquietos trabajamos en incorporar nuevos
								productos, nuestro compromiso es brindar soluciones, ayudando a las empresas
								constructoras ofreciendo productos de excelente calidad.
							</Card.Text>

							<Card.Text className="mt-3 pt-3">
								Es por eso que diseñamos, producimos y proveemos una amplia variedad de productos, enfocados en
								mantener siempre los estándares de calidad exigidos por nuestros clientes.
							</Card.Text>
						</Col>

						<Col sm={12} md={12} lg={8}  className='d-flex flex-column justify-content-center align-items-center'>
							<Image src={imagen} fluid className='rounded'/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
}

export default WhoWeAre;
