import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import imagen from '../../Assets/Images/Floors/im2.jpeg';

function OurProducts() {
	let fondoHeader = { backgroundColor: 'transparent' };

	return (
		<Container className="mt-5" id='OurProducts'>
			<Card className="mt-1">
				<Card.Body>
					<Row>
						<Col
							sm={12}
							md={12}
							lg={8}
							className="d-flex flex-column justify-content-center align-items-center"
						>
							<Image src={imagen} fluid className='rounded'/>
						</Col>

						<Col
							sm={12}
							md={12}
							lg={4}
							className="d-flex flex-column justify-content-center align-items-center"
						>
							<Card.Header className="h2" style={fondoHeader}>
								¿Qué Hacemos?
							</Card.Header>

							<Card.Text className="mt-3 pt-3">
								Los pisos vinílicos SPC representan una solución vanguardista para renovar ambientes.
								Son el producto ideal para viviendas, oficinas, hoteles, establecimientos públicos y
								otros ámbitos. Están formados por un núcleo compuesto de polvo de piedra y polietileno
								sobre el que se aplica un laminado decorativo y una capa de resistencia a la abrasión.
							</Card.Text>

							<Card.Text className="mt-3 pt-3">
								Su gran versatilidad, alta tecnología, resistencia al agua y su sencillo método de
								instalación por encastre (sistema clic), su aspecto es realista y natural, el aislante
								acústico armoniza los espacios y su composición física permite su uso con calefacción
								por losa radiante.
							</Card.Text>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
}

export default OurProducts;
