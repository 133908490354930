import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function Footer() {
	let fontSize = { fontSize: '11px' };

	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="bottom">
			<Container>
				<p className="text-white mx-auto my-auto" style={fontSize}>
					<a
						href="http://distintivo.com.ar"
						className="text-decoration-none text-white"
						target="_blank"
						rel="noopener noreferrer"
					>
						{' '}
						© 2022 - [D]istintivo - Identidad Visual
					</a>
				</p>
			</Container>
		</Navbar>
	);
}

export default Footer;
